<template>
  <b-card-code title="资金明细列表">
    <!-- search input -->

    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'nickname'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.nickname }}</span>
        </span>

        <!-- Column: tag -->
       
        <span v-else-if="props.column.field === 'create_time'">
          {{ timestampToTime(props.row.create_time) }}
        </span>
        <!-- Column: Action -->
        <!-- <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <router-link
                  :to="{ name: 'WechatEdit', params: { id: props.row.id } }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>编辑</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmText(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span> -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BModal,
    VBModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      total: 0,
      cid: 0,
      desc: "",
      search: "",
      deviceid: "",
      typename: "",
      gendername: "",
      module1: "",
      roleSelect: null,
      roleOptions: [],
      accountSelect: null,
      accountOptions: [],
      accountid: "",
      dir: false,
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "所在微信",
          field: "wechatnick",
        },
        {
          label: "好友微信",
          field: "friendnick",
        },
        {
          label: "操作前金额",
          field: "beforejf",
        },
        {
          label: "操作金额",
          field: "czjf",
        },
        {
          label: "操作后金额",
          field: "afterjf",
        },
        {
          label: "操作类型",
          field: "type",
        },
        {
          label: "操作备注",
          field: "remark",
        },
        {
          label: "添加时间",
          field: "modifiedtime",
        },
       
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    gendersVariant() {
      const gendersColor = {
        /* eslint-disable key-spacing */
        0: "light-primary",
        1: "light-success",
        2: "light-danger",
        /* eslint-enable key-spacing */
      };
      return (genders) => gendersColor[genders];
    },
    gendersShow() {
      const typeVariant = {
        0: "未知",
        1: "男",
        2: "女",
        null: "未知",
      };
      return (status) => typeVariant[status];
    },
    typeVariant() {
      const typeVariant = {
        /* eslint-disable key-spacing */
        0: "light-primary",
        1: "light-success",
        2: "light-danger",
        3: "light-warning",
        4: "light-info",
        /* eslint-enable key-spacing */
      };
      return (status) => typeVariant[status];
    },
    typeShow() {
      const typeVariant = {
        1: "搜索QQ",
        3: "微信号",
        4: "QQ好友",
        8: "群聊",
        14: "群聊",
        10: "通讯录",
        13: "通讯录",
        15: "搜索手机号",
        17: "名片分享",
        18: "附近人",
        22: "摇一摇",
        45: "扫一扫",
        1000030: "扫一扫",
      };
      return (status) => typeVariant[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt
      .FundRecordspageList({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        this.total = res.data.data.records;
        this.rows = res.data.data.rows;
      });
  },
  methods: {
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;

      const D = `${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      } `;
      const h = `${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:`;
      const m = `${
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
      }:`;
      const s =
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      return Y + M + D + h + m + s;
    },
    zhubeidongshow(num) {
      console.log("source", num);
      num = parseInt(num);
      var a = 0;
      if (num < 1000000) {
        a = 1;
      } else if (num > 1000000) {
        a = 2;
      } else {
        a = 0;
      }
      console.log("a", a);
      const typeVariant = {
        0: "号码迁移",
        1: "主动",
        2: "被动",
      };
      return typeVariant[a];
    },
    zhubeidongshowVariant(num) {
      num = parseInt(num);
      var a = 0;
      if (num < 1000000) {
        a = 1;
      } else if (num > 1000000) {
        a = 2;
      } else {
        a = 0;
      }
      const zhubeidongshowVariant1 = {
        /* eslint-disable key-spacing */
        0: "light-primary",
        1: "light-success",
        2: "light-danger",
        /* eslint-enable key-spacing */
      };
      return zhubeidongshowVariant1[a];
    },
    indexSelect(value) {
      let obj = {};
      obj = this.accountOptions.find((item) => item.label === value);
      if (obj != null) {
        this.accountid = obj.value;
      }
    },

    handleSearch(searching) {
      useJwt
        .FundRecordspageList({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          nickname: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          if (this.total == 0) {
            useJwt
              .FundRecordspageList({
                cid: this.cid,
                page: 1,
                rows: this.pageLength,
                friendid: searching,
              })
              .then((res) => {
                this.total = res.data.data.records;
                this.rows = res.data.data.rows;
              });
          }
          this.rows = res.data.data.rows;
        });
      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .FundRecordspageList({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      console.log("翻页" + this.search);
    },
    handlePageChange(active) {
      useJwt
        .FundRecordspageList({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }
      console.log(this.desc);
      useJwt
        .FundRecordspageList({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      console.log(`${params[0].field} ${params[0].type}`);
    },
  },
  mounted() {
    // console.log("加载");
    // useJwt.queryFriendsAll({ cid: this.cid }).then((response) => {
    //   if (response.data.code == 0) {
    //     const forArr = response.data.data;
    //     console.log(`obj${JSON.stringify(forArr)}`);
    //     forArr.forEach((item, i) => {
    //       this.roleOptions.push({ label: item.name, value: item.id });
    //     });
    //   }
    // });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
